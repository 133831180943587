import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface containerState {
  containers: any[];
}

const initialState: containerState = {
  containers: [],
};

export const containerSlice = createSlice({
  name: 'containerSlice',
  initialState,
  reducers: {
    setContainersAction: (state, action: PayloadAction<any[]>) => {
      state.containers = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setContainersAction } = containerSlice.actions;

export default containerSlice.reducer;
