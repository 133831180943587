import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { useAppSelector } from '../store';
import { selectContainers } from '../store/selectors/containerSelector';
import { RichTreeView } from '@mui/x-tree-view';

type SelectContainerDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedContainer?: string;
  setSelectedContainer?: (containerId: string) => void;
};

const buildTree = (array: any[]) => {
  const tree: any[] = [];

  for (let i = 0; i < array.length; i++) {
    const node = array[i];

    if (node.parentid) {
      const parent: any = array.filter((elem) => elem.id === node.parentid).pop();
      if (parent) {
        parent.children.push(node);
      } else {
        if (tree[0]) {
          tree[0].children.push(node);
        }
      }
    } else {
      tree.push(node);
    }
  }

  return tree;
};

const SelectContainerDialog = (props: SelectContainerDialogProps) => {
  const { open, onClose, setSelectedContainer, selectedContainer } = props;
  const containers = useAppSelector(selectContainers);

  const [tree, setTree] = useState<any>([]);

  useEffect(() => {
    if (containers.length > 0) {
      setTree(buildTree(containers.map((container) => ({ ...container, children: [] })) as any[]));
    }
    if (containers.length === 0) {
      setTree([]);
    }
  }, [containers]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div>Select Container</div>
        <RichTreeView
          selectedItems={selectedContainer}
          items={tree.length > 0 ? tree[0].children : []}
          getItemId={(item: any) => item?.id}
          getItemLabel={(item: any) => item?.name}
          onItemClick={setSelectedContainer ? (_e, itemId) => setSelectedContainer(itemId) : () => null}
        />
      </DialogTitle>
    </Dialog>
  );
};

export default SelectContainerDialog;
