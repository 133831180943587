import { alpha, createTheme } from '@mui/material/styles';
import { CloseRounded, ExpandMoreRounded } from '@mui/icons-material';
// import { CheckboxIcon, CheckedIcon } from '../icons';

const color = {
  primary: '#1EC665',
  primaryLight: '#49D082',
  secondary: '#384250',
  secondaryLight: '#474747',
  error: '#FD5B4E',
  warning: '#FF9800',
  warningLight: '#FDAB32',
  success: '#1EC665',
  successDark: '#1dbf61',
  info: '#61bcff',
  divider: 'rgba(232, 238, 244, 0.75)',
  dividerDark: 'rgba(0, 0, 0, 0.13)',
  text1: '#384250',
  text2: '#4C5561',
  text3: 'rgba(0, 0, 0, 0.6)',
  bgMain: '#F8FAFC',
  bgDark: '#EEF2F7',
};

const size = {
  radius: 5,
};

declare module '@mui/material/styles' {
  interface PaletteColor {
    label?: string;
  }
  interface SimplePaletteColorOptions {
    label?: string;
  }
  interface Palette {
    bg: Palette['primary'];
  }
  interface PaletteOptions {
    bg: PaletteOptions['primary'];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: color.primary,
      light: color.primary,
      dark: color.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: color.secondary,
      light: color.secondary,
      dark: color.secondary,
    },
    error: {
      main: color.error,
      light: color.error,
      dark: color.error,
      contrastText: '#fff',
    },
    warning: {
      main: color.warning,
      light: color.warning,
      dark: color.warning,
      contrastText: '#fff',
    },
    success: {
      main: color.success,
      light: color.success,
      dark: color.successDark,
      contrastText: '#fff',
    },
    info: {
      main: color.info,
      light: color.info,
      dark: color.info,
      contrastText: '#fff',
    },
    text: {
      primary: color.text1,
      secondary: color.text2,
      disabled: color.text3,
    },
    bg: {
      main: color.bgMain,
      dark: color.bgDark,
      // light: '',
    },
    divider: color.divider,
  },
  shape: {
    borderRadius: size.radius,
  },
  spacing: (factor: number) => [0, 8, 12, 16, 24, 32, 40, 48, 56, 64, 72, 80][factor],
  typography: {
    fontFamily: ['Inter', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(
      ',',
    ),
    h1: {
      fontSize: '3.25rem',
      fontWeight: 600,
      color: color.text1,
      '@media(max-width: 600px)': {
        fontSize: '1.75rem',
      },
    },
    h2: {
      fontSize: '2.5rem',
      color: color.text1,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: color.text1,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      color: color.text1,
    },
    h5: {
      fontSize: '1.25rem',
      color: color.text1,
      '@media(max-width: 600px)': {
        fontSize: '0.88rem',
      },
    },
    h6: {
      fontSize: '0.88rem',
      color: color.text1,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          letterSpacing: '-0.03em',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingTop: '4.63px',
          paddingBottom: '4.63px',
          transition: 'none !important',
        },
        contained: {
          boxShadow: 'none',
          paddingTop: '5.63px',
          paddingBottom: '5.63px',
          '&.Mui-disabled': {
            boxShadow: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-containedError': {
            '&:hover': {
              backgroundColor: color.error,
            },
          },
          '&.MuiButton-containedWarning': {
            '&:hover': {
              backgroundColor: color.warningLight,
            },
          },
        },
        containedPrimary: {
          boxShadow: '0px 3px 6px rgba(30, 198, 101, 0.2)',
          '&:hover': {
            backgroundColor: color.primaryLight,
            boxShadow: '0px 3px 6px rgba(30, 198, 101, 0.2)',
          },
          '&:active': {
            backgroundColor: '#16a854',
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: color.secondaryLight,
          },
          '&:active': {
            backgroundColor: '#000000',
          },
        },
        textInherit: {
          color: '#4C5561',
          '& .MuiButton-startIcon, .MuiButton-endIcon': {
            color: '#9AA4B2',
          },
        },
        outlinedInherit: {
          borderColor: color.divider,
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: color.bgMain,
          },
          '& .MuiButton-startIcon, .MuiButton-endIcon': {
            color: '#9AA4B2',
          },
        },
        startIcon: {
          marginRight: '6px',
          '&>*:nth-of-type(1)': {
            fontSize: '20px',
          },
        },
        endIcon: {
          marginLeft: '6px',
          '&>*:nth-of-type(1)': {
            fontSize: '20px',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: color.text3,
          '&.MuiBadge-colorPrimary': {
            backgroundColor: color.primary,
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CloseRounded />,
      },
      styleOverrides: {
        root: {
          '&.MuiChip-filled': {
            '&.MuiChip-colorDefault': {
              backgroundColor: alpha(color.divider, 0.6),
              color: color.text2,
            },
            '& .MuiChip-deleteIconColorDefault': {
              color: color.text2,
            },
          },
          '& .MuiChip-icon': {
            color: 'currentColor',
          },
        },
        outlined: {
          borderColor: color.divider,
        },
        label: {
          fontWeight: 500,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        // icon: <CheckboxIcon />,
        // checkedIcon: <CheckedIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          marginRight: '8px',
          color: color.text3,
          '&.Mui-disabled': {
            '#Checkbox': {
              fill: color.secondary,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'static',
          transform: 'none',
          marginBottom: '8px',
          fontSize: '0.8rem',
          '&.Mui-focused': {
            color: `${color.text2} !important`,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.88rem',
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
      styleOverrides: {
        paper: {
          border: `1px solid ${color.divider}`,
          borderRadius: size.radius,
          boxShadow: '0px 9px 14px rgba(74, 85, 104, 0.15) !important',
          minWidth: '120px',
          '& .MuiMenuItem-root': {
            color: color.text2,
            fontSize: '0.88rem',
            '& > .MuiSvgIcon-root': {
              fontSize: 18,
              marginRight: '12px',
            },
            '&:hover': {
              backgroundColor: '#F2F6FC',
            },
            '&.Mui-selected': {
              backgroundColor: '#EDF1F6',
              color: color.text2,
              fontWeight: 500,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: color.text3,
          '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
          },
        },
        positionEnd: {
          marginLeft: '14px',
        },
        positionStart: {
          marginRight: '14px',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: color.text2,
          minHeight: '3rem',
          display: 'flex !important',
          '&:hover': {
            backgroundColor: '#F2F6FC',
          },
          '&:active': {
            backgroundColor: '#EDF1F6',
            color: color.text2,
          },
          '&.Mui-selected': {
            backgroundColor: '#EDF1F6',
            color: color.text2,
            fontWeight: 500,
            '&.Mui-focusVisible': {
              backgroundColor: '#EDF1F6',
              color: color.text2,
              fontWeight: 500,
            },
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#EDF1F6',
            color: color.text2,
            fontWeight: 500,
          },
          '& > .MuiSvgIcon-root': {
            color: color.text3,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '0.9rem',
        },
        secondary: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05) !important',
          border: `1px solid ${color.divider}`,
          borderRadius: '11px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: size.radius,
          fontWeight: 500,
          backgroundColor: '#fff',
          '&:not(.MuiInputBase-multiline)': {
            minHeight: '36px',
          },
          '&:hover:not(.Mui-focused)': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.dividerDark,
            },
            '&.Mui-disabled': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: color.divider,
              },
            },
          },
          // '&.Mui-focused': {
          //   '& .MuiOutlinedInput-notchedOutline': {
          //     borderColor: color.dividerDark,
          //     borderWidth: '1px !important',
          //   },
          // },
          '&.Mui-disabled': {
            backgroundColor: color.bgMain,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: color.divider,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: color.divider,
            top: 0,
            '& legend': {
              display: 'none',
            },
          },
          '& .MuiSelect-icon': {
            fontSize: '1.3rem',
            color: color.text3,
          },
        },
        input: {
          borderRadius: size.radius,
          color: color.text2,
          fontWeight: 500,
          fontSize: '0.88rem',
          '&.Mui-disabled': {
            color: alpha(color.text3, 0.3),
            textFillColor: alpha(color.text3, 0.3),
          },
          '&::placeholder': {
            color: color.text3,
            opacity: '1',
            fontWeight: 400,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#000',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        IconComponent: ExpandMoreRounded,
      },
      styleOverrides: {
        select: {
          paddingTop: '6.5px',
          paddingBottom: '6.5px',
          '&[aria-expanded="false"] + * + * + .MuiOutlinedInput-notchedOutline': {
            borderColor: color.divider,
            borderWidth: '1px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiButton-contained.MuiButton-containedPrimary': {
            boxShadow: 'none',
          },
        },
        paper: {
          boxShadow: 'none',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.15rem',
          lineHeight: 1,
          padding: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 24px 24px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: size.radius,
          color: color.text3,
          padding: '5px',
          height: '36px',
          width: '36px',
          '&.outlined': {
            border: `1px solid ${color.divider}`,
          },
          '&.active': {
            backgroundColor: alpha(color.primary, 0.1),
            color: color.primary,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.25rem',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          textDecoration: 'none',
          backgroundColor: alpha(color.primary, 0.5),
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreRounded />,
        ChipProps: {
          size: 'small',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            paddingTop: '3px',
            paddingBottom: '3px',
            height: 'auto',
            minHeight: '36px',
          },
        },
        option: {
          fontSize: '0.88rem',
        },
        // endAdornment: {
        //   top: 'calc(50% - 18px)',
        // },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          color: '#64748b',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${color.divider}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '8px 16px',
            fontWeight: 400,
            color: color.text3,
            fontSize: '13px',
            borderBottom: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${color.divider}`,
          borderRight: `1px solid ${color.divider}`,
          padding: '12px 16px',
          '&:last-child': {
            borderRight: 0,
          },
        },
        sizeSmall: {
          padding: '6px 16px',
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: alpha('#000', 0.01),
            },
          },
          '&:hover': {
            backgroundColor: alpha('#000', 0.01),
            '& .show-on-hover': {
              opacity: 1,
            },
          },
          '& .show-on-hover': {
            opacity: 0,
            '&.selected': {
              opacity: '1 !important',
            },
          },
        },
      },
    },
  },
});

export default theme;
