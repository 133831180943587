import React, { useEffect, useState } from 'react';
import {
  AbstractViewInterface,
  ApiInterface,
  ContextMenuEntryInterface,
  CustomLayer,
  getApi,
  MouseEventCoordinatesInterface,
  PoiInterface,
} from '@navvis/ivion';
import AddTask from './AddTask';
import { setTaskTypesAction } from '../store/slices/taskSlice';
import { useAppDispatch, useAppSelector } from '../store';
import { selectTaskTypes } from '../store/selectors/taskSelector';
import axiosInstance from '../helpers/axiosInstanse';
import { selectContainers } from '../store/selectors/containerSelector';
import { setContainersAction } from '../store/slices/containerSlice';
import ShowTasksDialog from './ShowTasksDialog';

declare global {
  interface Window {
    ivApi: ApiInterface;
  }
}

class CreateTaskMenuEntryLayer extends CustomLayer {
  view: AbstractViewInterface;

  callback: (pos?: MouseEventCoordinatesInterface) => null;

  constructor(view: any, callback?: any) {
    super(view);
    this.callback = callback;
    this.view = view;
  }

  public onContextMenu(): ContextMenuEntryInterface {
    return {
      name: 'Create task',
      icon: 'fa-globe',
      callback: () => this.callback(),
    };
  }
}

class ShowNearestTasksMenuEntryLayer extends CustomLayer {
  view: AbstractViewInterface;
  callback: () => null;

  constructor(view: any, callback?: any) {
    super(view);
    this.callback = callback;
    this.view = view;
  }

  public onContextMenu(): ContextMenuEntryInterface {
    return {
      name: 'Show Nearest Tasks',
      icon: 'fa-globe',
      callback: () => this.callback(),
    };
  }
}

const Ivion = () => {
  const dispatch = useAppDispatch();
  const taskTypes = useAppSelector(selectTaskTypes);
  const containers = useAppSelector(selectContainers);

  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [showTaskDialog, setShowTaskDialog] = useState(false);

  useEffect(() => {
    taskTypes.length === 0 &&
      axiosInstance('/api/db/tasks/types')
        .then((res) => dispatch(setTaskTypesAction(res.data)))
        .catch((err) => console.error(err));
  }, [dispatch, taskTypes]);

  useEffect(() => {
    containers.length === 0 &&
      axiosInstance('/api/navvis/ivion/getContainers')
        .then((res) => dispatch(setContainersAction(res.data)))
        .catch((err) => console.error(err));
  }, [dispatch, containers]);

  useEffect(() => {
    const addTask = (poi: PoiInterface) => {
      try {
        const extendeddata = {
          navvis: {
            poi: {
              location: poi.location,
              attributes: poi.attributes,
              customData: poi.customData,
              datasetLocation: poi.datasetLocation,
              datasetOrientation: poi.datasetOrientation,
              descriptions: poi.descriptions,
              titles: poi.titles,
              id: poi.id,
              importance: poi.importance,
              orientation: poi.orientation,
            },
          },
        };
        const taskBody = {
          tasktitle: poi.title || '',
          taskcontent: poi.description || '',
          context: 'navvis',
          extendeddata,
        };
        axiosInstance.post('/api/navvis/ivion/createTask', taskBody).catch((err) => console.error(err));
      } catch (err) {
        console.error(err);
      }
    };

    getApi('https://realtimenavigation.iv.navvis.com/')
      .then((iv) => {
        iv.poi.service.onPoiSave.connect((poi) => addTask(poi));
        window.ivApi = iv;
        new CreateTaskMenuEntryLayer(window.ivApi.view.mainView, () => setOpenTaskDialog(true));
        new ShowNearestTasksMenuEntryLayer(window.ivApi.view.mainView, () => setShowTaskDialog(true));
      })
      .catch((error) => console.warn(error));
  }, []);

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: '<ivion></ivion>' }} />
      {openTaskDialog && <AddTask open={openTaskDialog} onClose={() => setOpenTaskDialog(false)} />}
      {showTaskDialog && <ShowTasksDialog open={showTaskDialog} onClose={() => setShowTaskDialog(false)} />}
    </>
  );
};

export default Ivion;
