import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { registerLicense } from '@syncfusion/ej2-base';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Ivion from './components/Ivion';
import { store } from './store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Ivion />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

registerLicense('ORg4AjUWIQA/Gnt2UlhhQlVMfV5CQmFKYVF2R2dJelRwdV9FZkwgOX1dQl9nSXdRdUVmWXpaeXJVQ2g=');

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </Provider>,
);
