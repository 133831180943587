import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CloseRounded, DriveFileMoveRounded, ExpandMoreRounded } from '@mui/icons-material';
import { useAppSelector } from '../store';
import { selectTaskTypes } from '../store/selectors/taskSelector';
import axiosInstance from '../helpers/axiosInstanse';
import SelectContainerDialog from './SelectContainerDialog';
import { selectContainers } from '../store/selectors/containerSelector';

const ContentWrap = styled(Box)(() => ({
  position: 'relative',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& .file-drop': {
    flex: 1,
  },
}));

type AddTaskProps = {
  open: boolean;
  onClose: () => void;
};

const AddTask = (props: AddTaskProps) => {
  const { open, onClose } = props;
  const taskTypes = useAppSelector(selectTaskTypes);
  const containers = useAppSelector(selectContainers);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [taskType, setTaskType] = useState<any>(taskTypes.length > 0 ? taskTypes[0].id : '');
  const [loading, setLoading] = useState(false);
  const [selectContainerDialog, setSelectContainerDialog] = useState<boolean>(false);
  const [selectedContainer, setSelectedContainer] = useState<string>('00000000-0000-0000-0000-000000000000');

  const addTask = useCallback(async () => {
    setLoading(true);
    const cursorPosition = window.ivApi.view.mainView.getCurrentCursorPosition();

    const poi = window.ivApi.poi.repository.create();

    const poiType = await window.ivApi.poi.poiTypeRepository.findAll().then((poiTypes) => {
      return poiTypes[1];
    });

    poi.titles.en = title;
    poi.descriptions.en = description;
    poi.datasetLocation = cursorPosition.datasetLocation;
    poi.datasetOrientation = cursorPosition.datasetOrientation;
    poi.orientation = cursorPosition.orientation;
    poi.poiType = poiType;
    poi.location = cursorPosition.location;

    const createdPoi = await window.ivApi.poi.repository.save(poi).then((pois) => {
      const savedPoi = pois[0];
      window.ivApi.poi.service.refreshPois();
      return savedPoi;
    });
    const extendeddata = {
      navvis: {
        poi: {
          location: createdPoi.location,
          attributes: createdPoi.attributes,
          customData: createdPoi.customData,
          datasetLocation: createdPoi.datasetLocation,
          datasetOrientation: createdPoi.datasetOrientation,
          descriptions: createdPoi.descriptions,
          titles: createdPoi.titles,
          id: createdPoi.id,
          importance: createdPoi.importance,
          orientation: createdPoi.orientation,
        },
      },
    };
    const taskBody: any = {
      tasktitle: title,
      taskcontent: description,
      typeid: taskType,
      context: 'navvis',
      extendeddata,
      parenttype: 'container',
      parentid: selectedContainer,
    };
    await axiosInstance
      .post('/api/navvis/ivion/createTask', taskBody)
      .then(() => onClose())
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [title, description, taskType, onClose, selectedContainer]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          flexDirection: 'row',
          overflow: 'inherit',
          alignItems: 'stretch',
        },
      }}
    >
      <ContentWrap>
        <DialogTitle>
          Add Task
          <IconButton onClick={() => setSelectContainerDialog(true)}>
            <DriveFileMoveRounded />
          </IconButton>
          {selectedContainer && (
            <span onClick={() => setSelectContainerDialog(true)}>
              {containers.find((container) => container.id === selectedContainer)?.name}
            </span>
          )}
          <IconButton onClick={onClose} sx={{ top: '24px', right: '16px', position: 'absolute' }}>
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={12}>
              <InputLabel>Title</InputLabel>
              <TextField value={title} onChange={(e) => setTitle(e.target.value)} fullWidth />
            </Grid>
            <Grid size={12}>
              <InputLabel>Description</InputLabel>
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
              />
            </Grid>
            <Grid size={6}>
              <InputLabel>Type</InputLabel>
              <Select
                labelId="type-label"
                id="container-type-select"
                value={taskType}
                onChange={(e) => setTaskType(e.target.value)}
                name="type"
                required
                fullWidth
                sx={{ m: 0 }}
                IconComponent={ExpandMoreRounded}
                displayEmpty
              >
                {taskTypes.map((ct: any) => (
                  <MenuItem key={ct.id} value={ct.id}>
                    {ct.tasktype}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="inherit">
            Cancel
          </Button>
          <Button disabled={loading} variant="contained" onClick={() => addTask()}>
            Create Task
          </Button>
        </DialogActions>
      </ContentWrap>
      {selectContainerDialog && (
        <SelectContainerDialog
          open={selectContainerDialog}
          onClose={() => setSelectContainerDialog(false)}
          selectedContainer={selectedContainer}
          setSelectedContainer={setSelectedContainer}
        />
      )}
    </Dialog>
  );
};

export default AddTask;
