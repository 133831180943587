import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GXPAAS_BASE_USL,
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY,
    'api-key-id': process.env.REACT_APP_API_KEY_ID,
  },
});

export default axiosInstance;
