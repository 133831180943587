import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import axiosInstance from '../helpers/axiosInstanse';

type ShowTasksDialogProps = {
  open: boolean;
  onClose: () => void;
};

type vector = { x: number; y: number; z: number };

const ShowTasksDialog = (props: ShowTasksDialogProps) => {
  const { open, onClose } = props;

  const [tasks, setTasks] = useState<any[]>([]);

  const getVectorDistance = (v1: vector, v2: vector) => {
    const dx = v1.x - v2.x;
    const dy = v1.y - v2.y;
    const dz = v1.z - v2.z;

    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  };

  useEffect(() => {
    const getPoiTasks = async () => {
      const [poisData, tasksPoi] = await Promise.all([
        window.ivApi.poi.repository.findAll(),
        axiosInstance('/api/navvis/ivion/getPoiTasks')
          .then((res) => res.data)
          .catch((err) => console.error(err)),
      ]);
      const poiIds = poisData.map((poi) => poi.id + '');
      setTasks(
        tasksPoi
          .filter((taskItem: any) => poiIds.includes(taskItem.poiid))
          .map((taskItem: any) => ({
            ...taskItem,
            distance: getVectorDistance(
              taskItem.extendeddata.navvis.poi.location,
              window.ivApi.view.mainView.getCurrentCursorPosition().location,
            ),
          }))
          .sort((a: { distance: number }, b: { distance: number }) => a.distance - b.distance),
      );
    };
    getPoiTasks();
  }, []);

  const addRoute = async (poiId: number) => {
    try {
      const pois = await window.ivApi.poi.repository.findAll();
      const selectedPoi = pois.find((poi) => poi.id === poiId);
      if (selectedPoi) {
        await window.ivApi.routing.route(null as any, selectedPoi);
        onClose();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Nearest Tasks</DialogTitle>
      <DialogContent>
        {tasks.map((task) => (
          <Box key={task.id} sx={{ border: '1px solid ', width: '100%', minHeight: '20px' }}>
            <Box>
              {task.poititle}({task.distance})
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: task.poidescription }} />
            <Button onClick={() => addRoute(+task.poiid)}>Go to Poi</Button>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default ShowTasksDialog;
