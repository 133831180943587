import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface taskState {
  taskTypes: any[];
  poiTasks: any[];
}

const initialState: taskState = {
  taskTypes: [],
  poiTasks: [],
};

export const taskSlice = createSlice({
  name: 'taskSlice',
  initialState,
  reducers: {
    setTaskTypesAction: (state, action: PayloadAction<any[]>) => {
      state.taskTypes = action.payload;
    },
    setPoiTasksAction: (state, action: PayloadAction<any[]>) => {
      state.poiTasks = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTaskTypesAction, setPoiTasksAction } = taskSlice.actions;

export default taskSlice.reducer;
